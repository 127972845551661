import React from "react";

import BlockContent from "@sanity/block-content-to-react";
import { graphql } from "gatsby";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import SanityImage from "gatsby-plugin-sanity-image";
import PropTypes from "prop-types";

import Masonry from "../../components/Masonry/Masonry";
import { PoiIcon54, QuizIcon54, TourIcon54 } from "../../icons";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import MainLayout from "../layouts/MainLayout";
import Seo from "../Seo/Seo";

const PaperChaseOverviewPage = ({
  data: {
    page,
    paperChases,
  },
}) => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const lang = language === "de-DE" ? "" : `/${language}`;

  return (
    <MainLayout breadcrumb={<Breadcrumb id={page.id} />}>
      <div className="mx-18 mt-30">
        <div>
          <div>
            <div>
              <div className="text-h1-mobile uppercase">
                {t("ourPagerchases")}
              </div>
              <div className="text-lead-mobile mt-18">
                {page._rawQuickInfoText && (
                  <BlockContent
                    blocks={page._rawQuickInfoText}
                  />
                )}
              </div>
            </div>
            {/* Overview of all available paperChases */}
            <div>
              <div className="text-h4-mobile mt-72 uppercase mb-9">
                {paperChases.nodes.length} {t("results")}
              </div>
            </div>
            <Masonry breakpointCols={2}>
              {paperChases.nodes.map((paperChase) => (
                <div key={paperChase.id}>
                  <a href={`${lang}/${t("paperchase")}/${paperChase.slug?.current?.replace(";", "-")}`}>
                    {paperChase._rawImage && (
                      <div>
                        <SanityImage
                          {...paperChase._rawImage}
                          width={330}
                        />
                      </div>
                    )}
                    <div className="mt-2.5">
                      {paperChase.paperChaseStation.length} {t("stations")}
                    </div>
                    <div className="text-h5-mobile uppercase">
                      {paperChase.name}
                    </div>
                  </a>
                </div>
              ))}
            </Masonry>
            {/* Explanation: How it works */}
            <div className="mt-36">
              <div className="text-h1-mobile uppercase">
                {t("howItWorks")}
              </div>
              <div className="mt-60">
                <TourIcon54 />
                <div className="text-h4-mobile uppercase mt-18">
                  {t("startPaperchase")}
                </div>
                <div className="text-body1-mobile mt-12">
                  {t("startPaperchaseDescription")}
                </div>
              </div>
              <div className="mt-30">
                <PoiIcon54 />
                <div className="text-h4-mobile uppercase mt-18">
                  {t("findPaperchaseStation")}
                </div>
                <div className="text-body1-mobile mt-12">
                  {t("findPaperchaseStationDescription")}
                </div>
              </div>
              <div className="mt-30">
                <QuizIcon54 />
                <div className="text-h4-mobile uppercase mt-18">
                  {t("findPaperchaseResults")}
                </div>
                <div className="text-body1-mobile mt-12">
                  {t("findPaperchaseResultsDesctiption")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: sanityPaperChaseOverview(id: {eq: $id}) {
      id
      title
      _rawQuickInfoText
      slug {
        current
      }
      Metadata {
        ...MetaData
      }
    }
    paperChases: allSanityPaperChase {
      nodes {
        id
        name
        image {
          _key
          _type
          _rawAsset
          _rawHotspot
          _rawCrop
        }
        slug {
          current
        }
        _rawDescription
        _rawImage
        paperChaseStation {
          _createdAt
          _id
          _key
          _rawArtwork
          _rawPaperChaseQuestionKids
          _rawPaperChaseQuestionPro
          _rawPaperChaseQuestionSchool
          _rawPaperChaseQuestionVisitor
          _rev
          _type
          _updatedAt
          answerType
        }
      }
    } 
  }
`;

PaperChaseOverviewPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default PaperChaseOverviewPage;
